
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        










































































































































































































































































::v-deep {
    .modal-card {
        max-width: 800px;
        width: 100vw;
    }
}

.row-grid {
    &--checkbox-cover {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
}

:root {
    --hue: 0;
}

.line-wrapper {
    overflow: hidden;
    max-height: 6px;
    border-radius: 1rem;
}

.line-item {
    max-width: 25%;
    padding-top: 3px;
    padding-bottom: 3px;
}

.value-icon {
    padding: 6px;
    margin-right: 6px;
    border-radius: 50%;
}
