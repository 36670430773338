
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




















































































































.row-grid {
    &--checkbox-cover {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }
}

.line-item {
    max-width: 25%;
    padding-top: 3px;
    padding-bottom: 3px;
}
